import PropTypes from 'prop-types';
import React from 'react';
import PageContent from './PageContent';

export const HTMLContent = ({ content }) => (
  <PageContent dangerouslySetInnerHTML={{ __html: content }} />
);

const Content = ({ content }) => <PageContent>{content}</PageContent>;

Content.propTypes = {
  content: PropTypes.node,
};

HTMLContent.propTypes = Content.propTypes;

export default Content;
