import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Cookies from '../components/Cookies';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/UI/Content';
import ContentContainer from '../components/UI/ContentContainer';

const Container = styled(ContentContainer)`
    margin-top: 50px;
`;

export const LegalPageTemplate = ({ content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <section>
      <Cookies/>
      <Container>
        <PageContent className="content" content={content} />
      </Container>
    </section>
  );
};

LegalPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const LegalPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <LegalPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

LegalPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default LegalPage;

export const legalPageQuery = graphql`
  query LegalPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
